import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './afk-guide.scss';
import { AFKCharacter } from '../../../modules/afk/common/components/afk-character';
import { Tab, Tabs } from 'react-bootstrap';
import { AFKItem } from '../../../modules/afk/common/components/afk-item';

const AFKGuidesDreamSnow: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page afk-journey afk-guide'}
      game="afk"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/afk-journey/">AFK Journey</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/afk-journey/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Dream Realm - Snow Stomper</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/afk/categories/category_yeti.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>AFK Journey Snow Stomper</h1>
          <h2>
            Dream Realm Snow Stomper guide that explains the boss mechanics and
            how to beat it!
          </h2>
          <p>
            Last updated: <strong>11/12/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Snow Stomper" />
        <StaticImage
          src="../../../images/afk/generic/boss_snow.webp"
          alt="Guides"
        />
        <p>
          <strong>Snow Stomper</strong> is one of the 8 available Dream Realm
          bosses you will encounter in AFK Journey. In this guide we will go
          over the boss mechanics and the best characters and teams to use
          against him.
        </p>
        <SectionHeader title="What’s the meta team?" />
        <p>
          Realistically speaking,{' '}
          <strong>
            your meta team will depend on your specific box, and your account
            focus
          </strong>
          , so use your highest rarity DPS carry characters, along with whoever
          can best boost their damage.{' '}
          <strong className="gold">
            Try running Dual carry, as currently there is no way to boost a
            Hypercarry’s damage high enough to make it worth running only one
          </strong>
          . Keep faction bonus in mind but don’t run suboptimal characters just
          to force it.
        </p>
        <p>
          Still, below you can find a few example teams. Big thanks to{' '}
          <StaticImage
            src="../../../images/afk/icons/afkanalytica.png"
            alt="AFK Analytica"
          />{' '}
          <strong className="yellow">AFK Analytica</strong> for the team
          building tool. You can find their website{' '}
          <a
            href="https://www.afkanalytica.com/"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>{' '}
          and their discord{' '}
          <a
            href="https://www.discord.gg/analytica"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
        <SectionHeader title="Season 2 Teams (Endless)" />
        <Tabs
          defaultActiveKey="Team1"
          transition={false}
          id="boss-tabs-season"
          className="nav-tabs"
        >
          <Tab
            eventKey="Team1"
            title={<>Team #1 - Best Team</>}
            className="with-bg"
          >
            <h5>Team #1 - Best Team (Harak + Phraesto + Reinier)</h5>
            <StaticImage
              src="../../../images/afk/dream_s/snow_1.webp"
              alt="Guides"
            />
            <p>
              Credits to <strong>Atraxy</strong> from AFK Analytica Discord.
            </p>
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="harak" enablePopover /> acts
                as the main carry.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="lily-may" enablePopover />{' '}
                acts as secondary carry.
              </li>
              7
              <li>
                <AFKCharacter mode="inline" slug="reinier" enablePopover /> acts
                as buffer and debuffer (<strong>Mythic+</strong> required).
              </li>
              <li>
                <AFKCharacter mode="inline" slug="phraesto" enablePopover />{' '}
                acts as tank and energy regen source.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="mikola" enablePopover /> acts
                as the healer and buffer.
              </li>
              <li>Artifacts:</li>
              <ul>
                <li>
                  <AFKItem name="Resonating Spell" /> - Best overall
                  performance.
                </li>
              </ul>
            </ul>
          </Tab>
          <Tab
            eventKey="Team2"
            title={<>Team #2 - Alt Team</>}
            className="with-bg"
          >
            <h5>Team #2 - Alt Team (Reinier)</h5>
            <StaticImage
              src="../../../images/afk/dream_s/snow_2.webp"
              alt="Guides"
            />
            <p>
              Credits to <strong>Dragon Sonja</strong> from AFK Analytica
              Discord.
            </p>
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="lily-may" enablePopover />{' '}
                acts as the main carry.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="reinier" enablePopover /> acts
                as buffer and debuffer (<strong>Mythic+</strong> required).
              </li>
              <li>
                <AFKCharacter mode="inline" slug="sonja" enablePopover /> acts
                as buffer and secondary carry.
              </li>
              <li>
                <AFKCharacter
                  mode="inline"
                  slug="smokey-and-meerky"
                  enablePopover
                />{' '}
                acts as buffer, healer and secondary carry.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="mikola" enablePopover /> acts
                as the healer and buffer.
              </li>
              <li>Artifacts:</li>
              <ul>
                <li>
                  <AFKItem name="Resonating Spell" /> - Best overall
                  performance.
                </li>
              </ul>
            </ul>
          </Tab>
          <Tab
            eventKey="Team3"
            title={<>Team #3 - Alt Team</>}
            className="with-bg"
          >
            <h5>Team #3 - Alt Team (Reinier)</h5>
            <StaticImage
              src="../../../images/afk/dream_s/snow_3.webp"
              alt="Guides"
            />
            <p>
              Credits to <strong>D3R4G0N</strong> from AFK Analytica Discord.
            </p>
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="lily-may" enablePopover />{' '}
                acts as the main carry.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="shakir" enablePopover /> acts
                as tank, buffer and secondary carry.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="reinier" enablePopover /> acts
                as buffer and debuffer (<strong>Mythic+</strong> required).
              </li>
              <li>
                <AFKCharacter mode="inline" slug="mikola" enablePopover /> acts
                as the healer and buffer.
              </li>
              <li>
                <AFKCharacter
                  mode="inline"
                  slug="smokey-and-meerky"
                  enablePopover
                />{' '}
                acts as buffer, healer and secondary carry.
              </li>
              <li>Artifacts:</li>
              <ul>
                <li>
                  <AFKItem name="Resonating Spell" /> - Best overall
                  performance.
                </li>
              </ul>
            </ul>
          </Tab>
          <Tab
            eventKey="Team4"
            title={<>Team #4 - No Celehypo</>}
            className="with-bg"
          >
            <h5>Team #4 - No Celehypo (Sonja + Lily May)</h5>
            <StaticImage
              src="../../../images/afk/dream_s/snow_4.webp"
              alt="Guides"
            />
            <p>
              Credits to <strong>Eri</strong> from Prydwen.
            </p>
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="lily-may" enablePopover />{' '}
                acts as the main carry.
              </li>
              <ul>
                <li>
                  <AFKCharacter mode="inline" slug="marilee" enablePopover /> or{' '}
                  <AFKCharacter mode="inline" slug="korin" enablePopover /> can
                  be used as alternatives to Lily May.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="sonja" enablePopover /> acts
                as buffer and secondary carry.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="shakir" enablePopover /> acts
                as buffer and secondary carry.
              </li>
              <li>
                <AFKCharacter mode="inline" slug="mikola" enablePopover /> acts
                as the healer and buffer.
              </li>
              <ul>
                <li>
                  <AFKCharacter mode="inline" slug="koko" enablePopover /> can
                  be used as an alternative.
                </li>
              </ul>
              <li>
                <AFKCharacter mode="inline" slug="kruger" enablePopover /> acts
                as tank and debuffer.
              </li>
              <li>Artifacts:</li>
              <ul>
                <li>
                  <AFKItem name="Frontline Spell" /> - Best overall performance.
                </li>
              </ul>
            </ul>
          </Tab>
        </Tabs>
        <SectionHeader title="Boss Mechanics" />
        <p>
          The boss will start by attacking the closest character with a melee
          attack or ranged attack and then switch to melee attacks, pausing to
          use his AOE Breath attack from time to time. 10s into the battle, Snow
          Stomper summons a <strong>Blizzard</strong> that lasts for 20s, after
          which there will be a 10s cooldown before he casts it again, repeating
          this process 3 times over the course of the fight.
        </p>
        <p>
          After summoning the Blizzard, Snow Stomper{' '}
          <strong>freezes the unit with the highest accumulated damage</strong>,
          dealing damage over time until the snowman is destroyed. Sometimes,
          the boss will also attack the healthiest character, knocking them down
          and interrupting their actions for 1s.
        </p>
        <p>
          This stage does feature skill channeling interruption, so{' '}
          <AFKCharacter mode="inline" slug="smokey-and-meerky" enablePopover />{' '}
          gets very little uptime and we are forced to seek alternate ways of
          healing our team while maintaining offensive buffs.
        </p>
        <p>
          Characters who have any kind of like drain are advantageous, though at
          Supreme+ you can get away with simply running top dps carries such as
          <AFKCharacter mode="inline" slug="marilee" enablePopover /> by
          themselves, relying on{' '}
          <AFKCharacter mode="inline" slug="thoran" enablePopover />,{' '}
          <AFKCharacter mode="inline" slug="shakir" enablePopover /> or{' '}
          <AFKCharacter mode="inline" slug="reinier" enablePopover /> to give
          them just a little bit of extra survivability as their health slowly
          decreases over the course of the fight, resetting the fight if someone
          happens to die early.
        </p>
        <SectionHeader title="Action Summary" />
        <p>
          Snow Stomper takes about <strong>32</strong> actions in the span of 90
          seconds (the full length of the battle - if you survive through all of
          it that is). Here's a list of all possible actions:
        </p>
        <ul>
          <li>Melee attack,</li>
          <li>Ranged attack / Bleeding Ranged attack,</li>
          <li>AOE Breath attack (6x),</li>
          <li>Knockdown attack (4x),</li>
          <li>Snowman freeze attack (5x),</li>
          <li>AOE blizzard cast (3x).</li>
        </ul>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AFKGuidesDreamSnow;

export const Head: React.FC = () => (
  <Seo
    title="Snow Stomper | AFK Journey | Prydwen Institute"
    description="Dream Realm Snow Stomper guide that explains the boss mechanics and how to beat it!"
    game="afk"
  />
);
